import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { PropsWithChildren } from 'react';

const theme = createTheme({
    palette: {
        mode: 'light',
      background: {
        default: '#ebebeb'
      },
      primary: {
        main: 'rgb(29, 195, 40)'
      }
    }
  })

export const ThemeProvider = ({children}: PropsWithChildren) => {
    return (
        <MuiThemeProvider theme={theme}>
          <div
            style={{
              backgroundImage: 'url(bg_pro.jpg)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          >
            {children}
          </div>
        </MuiThemeProvider>
    )
}