import { Grid, Input, Paper, Loader, Table, ExportExcelButton, Button, Modal, DatePicker } from "../../components";
import { useAPI } from "../../API";
import { Fragment, useMemo } from "react";
import { enqueueSnackbar } from "notistack";
import { getOrderStatusLabel, STATUS_OPTIONS } from "../../utils";
import moment from "moment";
import { ButtonGroup, Link, Tooltip } from "@mui/material";
import { useToggle } from "../../hooks";
import { useFormik } from "formik";
import { AddOrderSchema } from "@vcm-painel/validations";
import { GridColDef } from "@mui/x-data-grid";

export function Admin () { 
    const [addOrderModalVisibility, addOrderModalVisibilityControls] = useToggle(false)
    const [orders, refetchOrders] = useAPI('/order')

    const [updatedOrderBuyerStatus, updateOrderBuyerStatus] = useAPI({
        url: '/order',
        method: 'PUT',
    }, {
        manual: true
    })

    const [updatedOrder, updateOrder] = useAPI({
        url: '/order',
        method: 'PUT',
    }, {
        manual: true
    })

    const [createdOrder, createOrder] = useAPI({
        url: '/order',
        method: 'POST',
    }, {
        manual: true
    })

    const addOrderFormik = useFormik({
        initialValues: {
            transaction: '',
            buyer_name: '',
            buyer_email: ''
        },
        isInitialValid: false,
        validateOnMount: true,
        validationSchema: AddOrderSchema,
        onSubmit: async (values, helpers) => {
            addOrderModalVisibilityControls.setFalse()

            const { status } = await createOrder({
                data: values
            })

            if (status <= 299) {
                enqueueSnackbar('Pedido cadastrado com sucesso!', {variant: 'success'})
            }
            

            helpers.resetForm()
            refetchOrders()
        }
    })

    const columns: GridColDef[] = useMemo((): GridColDef[] => {
        return [
            { field: 'id', headerName: 'ID', width: 50 },
            { field: 'created_at', headerName: 'Data', flex: 1, valueFormatter: ({value}) => {
                return moment(value).format('DD/MM/YYYY HH:mm:ss')
            } },    
            { field: 'address_filled_at', headerName: 'Data do Preenchimento', flex: 1, valueGetter: ({row}) => row.buyer.address_filled_at, valueFormatter: ({value}) => {
                return value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : ''
            } },  
            { field: 'transaction', headerName: 'HP', flex: 1 },
            { field: 'buyer_name', headerName: 'Nome', flex: 1, valueGetter: ({row}) => row.buyer.name},
            { field: 'buyer_email', headerName: 'E-mail', flex: 1, valueGetter: ({row}) => row.buyer.email, renderCell: ({value}) => (
                <Link href={`mailto:${value}`}>{value}</Link>
            )},
            { field: 'approved', headerName: 'Compra Aprovada (7 dias)', width: 100, valueFormatter: ({value}) => value ? 'Sim' : 'Não'},
            { field: 'ship_date', type: 'date', headerName: 'Data de Envio', flex: 1, valueGetter: ({value}) => value ? new Date(value) : null, renderCell: ({row, value}) => {
                return (
                    <DatePicker 
                        disabled={row.status !== 'SENT'}
                        value={value ? moment(value) : undefined}
                        onChange={async (e) => {
                            const { status } = await updateOrder({
                                url: `/order/${row.id}`,
                                data: {
                                    ship_date: e?.toDate()
                                }
                            })

                            if (status <= 299) {
                                enqueueSnackbar('Pedido atualizado com sucesso!', { variant: 'success' })
                            }

                            await refetchOrders()
                        }}
                    />
                )
            }},
            {
                field: 'status', headerName: 'Situação', flex: 1, align: 'right',
                valueGetter: ({value}) => getOrderStatusLabel(value),
                renderCell: ({ row }) => {
                    return (
                        <Tooltip
                            title={row.status === 'CANCELED' ? "Não é possível alterar o status de pedidos cancelados." : ''}
                        >
                            <div style={{width: '100%'}}>
                                <Input 
                                    select
                                    options={STATUS_OPTIONS}
                                    disabled={row.status === 'CANCELED'}
                                    onChange={async (item) => {
                                        const { status } = await updateOrderBuyerStatus(`/order/${row.id}/status/${item.target.value}`)
                                        if (status <= 299) {
                                            enqueueSnackbar('Pedido atualizado com sucesso!', { variant: 'success' })
                                        }
                                        await refetchOrders()
                                    }}
                                    value={STATUS_OPTIONS.find(item => item.value === row.status)?.value}
                                />
                            </div>
                        </Tooltip>
                    )
                }
            },
        ]
    }, [])
    
    return (
        <Fragment>
            <Modal
                open={addOrderModalVisibility}
                onClose={addOrderModalVisibilityControls.setFalse}
                title="Adicionar Pedido"
                onConfirm={addOrderFormik.handleSubmit}
                confirmLabel="Adicionar"
            >
                <Grid container spacing={2} padding={2} minWidth={"30vw"}>
                    <Grid item xs={12}>
                        <Input 
                            label="HP"
                            name="transaction"
                            onChange={addOrderFormik.handleChange}
                            value={addOrderFormik.values.transaction}
                            helperText={addOrderFormik.touched.transaction && addOrderFormik.errors.transaction}
                            onBlur={addOrderFormik.handleBlur}
                            error={!!addOrderFormik.touched.transaction && !!addOrderFormik.errors.transaction}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input 
                            label="Nome do Comprador"
                            name="buyer_name"
                            onChange={addOrderFormik.handleChange}
                            value={addOrderFormik.values.buyer_name}
                            helperText={addOrderFormik.touched.buyer_name && addOrderFormik.errors.buyer_name}
                            onBlur={addOrderFormik.handleBlur}
                            error={!!addOrderFormik.touched.buyer_name && !!addOrderFormik.errors.buyer_name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input 
                            label="E-mail do Comprador"
                            name="buyer_email"
                            onChange={addOrderFormik.handleChange}
                            value={addOrderFormik.values.buyer_email}
                            helperText={addOrderFormik.touched.buyer_email && addOrderFormik.errors.buyer_email}
                            onBlur={addOrderFormik.handleBlur}
                            error={!!addOrderFormik.touched.buyer_email && !!addOrderFormik.errors.buyer_email}
                        />
                    </Grid>
                </Grid>
            </Modal>
        <Grid container justifyContent="center" minHeight={"100vh"} padding={4} alignItems="flex-start">
            {orders.loading || updatedOrderBuyerStatus.loading || createdOrder.loading || updatedOrder.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (
                    <Fragment>
                <Grid item xs={12} sm={10} textAlign="center">
                    <img src="/logo.png" alt="Logo" style={{width: 500}}/>
                    <div style={{ textAlign: 'right', marginBottom: 15, marginTop: 30 }}>
                        <Button
                            onClick={addOrderModalVisibilityControls.setTrue}
                            sx={{marginRight: 1}}
                        >
                            Adicionar
                        </Button>
                        <ExportExcelButton 
                            data={(orders?.data || [])} 
                                columns={[
                                { field: 'id', headerName: 'ID' },
                                { field: 'address_filled_at', headerName: 'Data do Preenchimento', valueGetter: ({row}) => row.buyer.address_filled_at, valueFormatter: ({value}) => {
                                    return value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : ''
                                } }, 
                                { field: 'transaction', headerName: 'HP' },
                                { field: 'buyer_name', headerName: 'Nome', valueGetter: ({row}) => row.buyer.name },
                                { field: 'buyer_email', headerName: 'E-mail', valueGetter: ({row}) => row.buyer.email },
                                { field: 'delivery_address_street', headerName: 'Logradouro', valueGetter: ({row}) => row.buyer.address_street },
                                { field: 'delivery_address_number', headerName: 'Número', valueGetter: ({row}) => row.buyer.address_number },
                                { field: 'delivery_address_complement', headerName: 'Complemento', valueGetter: ({row}) => row.buyer.address_complement },
                                { field: 'delivery_address_neighborhood', headerName: 'Bairro', valueGetter: ({row}) => row.buyer.address_neighborhood },
                                { field: 'delivery_address_cep', headerName: 'CEP/Código Postal', valueGetter: ({row}) => row.buyer.address_cep },
                                { field: 'delivery_address_city', headerName: 'Cidade', valueGetter: ({row}) => row.buyer.address_city },
                                { field: 'delivery_address_state', headerName: 'Estado', valueGetter: ({row}) => row.buyer.address_state },
                                { field: 'delivery_address_country', headerName: 'País', valueGetter: ({row}) => row.buyer.address_country },
                                { field: 'approved', headerName: 'Compra Aprovada (7 dias)', valueFormatter: ({value}) => value ? 'Sim' : 'Não'},
                                { field: 'status', headerName: 'Situação', valueFormatter: ({value}) => getOrderStatusLabel(value)},
                            ]} 
                            fileName={'pedidos-vcm'}
                            label="Exportar"
                        />
                    </div>
                    <Paper elevation={1}>
                    <Table
                        rowHeight={65}
                        columns={columns}
                        rows={(orders?.data || [])}
                    />
                    </Paper>
                </Grid>
                    </Fragment>)}
        </Grid>

        </Fragment>
    )
}