import { useFormik } from "formik";
import { Grid, Button, Input, Autocomplete, Alert, AlertTitle, Loader, Modal, Typography } from "../../components";
import { SearchOrderSchema, UpdateAddressSchema } from '@vcm-painel/validations'
import { useAPI } from "../../API";
import { Fragment, useEffect, useMemo } from "react";
import useAxios from "axios-hooks";
import { enqueueSnackbar } from "notistack";
import { COUNTRY_OPTIONS, formatCEP, getOrderStatusColor, getOrderStatusLabel } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useToggle } from "../../hooks";
import moment from "moment";

export function Home () { 
    const navigate = useNavigate()
    
    const [confirmModalVisibility, confirmModalVisibilityControls] = useToggle(false)
    
    const [order, searchOrder] = useAPI({
        url: '/order/search',
        method: 'GET'
    }, {
        manual: true
    })

    const [updatedOrderBuyerAddress, updateOrderBuyerAddress] = useAPI({
        url: `/order/${order.data?.id}/address`,
        method: 'PUT'
    }, {
        manual: true
    })
    
    const formik = useFormik({
        initialValues: {
            transaction: '',
            email: '',
        },
        onSubmit: async (values) => {
            searchOrder({
                params: values
            }).catch(() => {
                enqueueSnackbar('Pedido não encontrado.', {variant: 'error'})
            })
        },
        validationSchema: SearchOrderSchema,
        validateOnMount: true
    })

    const { transaction, email } = formik.values

    const addressFormik = useFormik({
        initialValues: {
            address_cep: '',
            address_street: '',
            address_number: '',
            address_neighborhood: '',
            address_complement: '',
            address_city: '',
            address_state: '',
            address_country: 'BR',
            transaction: transaction,
            buyer_email: email,
        },
        onSubmit: async (data) => {
            const { status } = await updateOrderBuyerAddress({
                data
            })

            if (status <= 299) {
                enqueueSnackbar('Endereço de entrega atualizado com sucesso!', {variant: 'success'})
                navigate('/obrigado')
            }

            confirmModalVisibilityControls.setFalse()
        },
        validationSchema: UpdateAddressSchema,
        validateOnMount: true,
        validateOnChange: true,
        initialStatus: 'draft'
    })

    const { resetForm, validateForm } = addressFormik

    useEffect(() => {
        if (order.data) {
            resetForm({
                values: {
                    address_cep: order.data?.buyer?.address_cep || '',
                    address_street: order.data?.buyer?.address_street || '',
                    address_number: order.data?.buyer?.address_number || '',
                    address_neighborhood: order.data?.buyer?.address_neighborhood || '',
                    address_complement: order.data?.buyer?.address_complement || '',
                    address_city: order.data?.buyer?.address_city || '',
                    address_state: order.data?.buyer?.address_state || '',
                    address_country: order.data?.buyer?.address_country || 'BR',
                    transaction: formik.values.transaction,
                    buyer_email: formik.values.email
                },
                status: 'initiated',
            })

            validateForm()
        }
    }, [order.data, resetForm, validateForm, formik.values.transaction, formik.values.email])
    
    const cleanCEP = addressFormik.values.address_cep.replace(/\D/ig, '')
    const country = addressFormik.values.address_country

    const [cepSearch, searchCEP] = useAxios(`https://viacep.com.br/ws/${cleanCEP}/json/`, {
        manual: true
    })
    useEffect(() => {
        if (cleanCEP.length === 8 && country === 'BR') {
            searchCEP()
        }
    }, [cleanCEP, searchCEP, country])

    useEffect(() => {
        if (cepSearch.data && !cepSearch.loading && !cepSearch.error) {
            addressFormik.setFieldValue('address_street', cepSearch.data.logradouro || '')
            addressFormik.setFieldValue('address_city', cepSearch.data.localidade || '')
            addressFormik.setFieldValue('address_state', cepSearch.data.uf || '')
            addressFormik.setFieldValue('address_neighborhood', cepSearch.data.bairro || '')
        }
    }, [cepSearch.data])
    return (
        <Grid container justifyContent="center" minHeight={"100vh"} padding={4} alignItems="center">
            {
                (updatedOrderBuyerAddress.loading || order.loading || cepSearch.loading) ? (
                    <Loader />
                ) : (
                    <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                                    {order.data && addressFormik.status === 'initiated' ? (
                                        <form onSubmit={addressFormik.handleSubmit}>
                                            <Modal
                                                open={confirmModalVisibility}
                                                onClose={confirmModalVisibilityControls.setFalse}
                                                title="Atenção!"
                                                onConfirm={addressFormik.handleSubmit}
                                                confirmLabel="Confirmar"
                                            >
                                                <div style={{
                                                    width: 400,
                                                    maxWidth: '80vw'
                                                }}>
                                                    <Typography>
                                                        Estes dados não poderão ser atualizados futuramente. Confira todos os dados antes de clicar no botão abaixo.
                                                    </Typography>
                                                </div>
                                            </Modal>
                                        <Grid container padding={2} spacing={2} justifyContent="center" textAlign={"center"}>
                                            <img src="/logo.png" alt="Logo" style={{width: '50%', marginBottom: 50}} onClick={() => window.location.href = '/' } />
                                            {order.data.approved ? (
                                            <Fragment>
                                                <Grid item xs={12} textAlign="left">
                                                    <Alert severity={getOrderStatusColor(order.data.status)}>
                                                        <AlertTitle>Situação:</AlertTitle>
                                                        {getOrderStatusLabel(order.data.status)}
                                                    </Alert>
                                                </Grid>
                                                <Grid item xs={12}> 
                                                    <Autocomplete
                                                        label="País"
                                                        onChange={({value}) => {
                                                            addressFormik.resetForm({
                                                                values: {
                                                                    ...addressFormik.values,
                                                                    address_cep: '',
                                                                    address_city: '',
                                                                    address_complement: '',
                                                                    address_neighborhood: '',
                                                                    address_number: '',
                                                                    address_state: '',
                                                                    address_street: '',
                                                                    address_country: value as string
                                                                },
                                                            })
                                                        }}
                                                        disabled={order.data.status !== 'AWAITING_ADDRESS'}
                                                        options={COUNTRY_OPTIONS}
                                                        onBlur={addressFormik.handleBlur}
                                                        value={COUNTRY_OPTIONS.find(item => item.value === addressFormik.values.address_country)}
                                                        helperText={addressFormik.touched.address_country && addressFormik.errors.address_country}
                                                        error={!!addressFormik.touched.address_country && !!addressFormik.errors.address_country}
                                                    />
                                                </Grid>
                                                {addressFormik.values.address_country && (
                                                <Fragment>
                                                    {addressFormik.values.address_country === 'BR' && (<Grid item xs={12}> 
                                                        <Input 
                                                            label="CEP"
                                                            name="address_cep"
                                                            onChange={(event) => {
                                                                addressFormik.setFieldValue('address_cep', formatCEP(event.target.value).slice(0, 9))
                                                            }}
                                                            disabled={order.data.status !== 'AWAITING_ADDRESS'}
                                                            onBlur={addressFormik.handleBlur}
                                                            value={addressFormik.values.address_cep}
                                                            helperText={addressFormik.touched.address_cep && addressFormik.errors.address_cep}
                                                            error={!!addressFormik.touched.address_cep && !!addressFormik.errors.address_cep}
                                                        />
                                                    </Grid>)}
                                                    <Grid item xs={12}> 
                                                        <Input 
                                                            label="Logradouro"
                                                            name="address_street"
                                                            disabled={order.data.status !== 'AWAITING_ADDRESS'}
                                                            onChange={addressFormik.handleChange}
                                                            value={addressFormik.values.address_street}
                                                            helperText={addressFormik.touched.address_street && addressFormik.errors.address_street}
                                                            onBlur={addressFormik.handleBlur}
                                                            error={!!addressFormik.touched.address_street && !!addressFormik.errors.address_street}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Input 
                                                            label="Número"
                                                            name="address_number"
                                                            disabled={order.data.status !== 'AWAITING_ADDRESS'}
                                                            onChange={addressFormik.handleChange}
                                                            value={addressFormik.values.address_number}
                                                            helperText={addressFormik.touched.address_number && addressFormik.errors.address_number}
                                                            onBlur={addressFormik.handleBlur}
                                                            error={!!addressFormik.touched.address_number && !!addressFormik.errors.address_number}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Input 
                                                            label="Complemento"
                                                            name="address_complement"
                                                            disabled={order.data.status !== 'AWAITING_ADDRESS'}
                                                            onChange={addressFormik.handleChange}
                                                            value={addressFormik.values.address_complement}
                                                            helperText={addressFormik.touched.address_complement && addressFormik.errors.address_complement}
                                                            onBlur={addressFormik.handleBlur}
                                                            error={!!addressFormik.touched.address_complement && !!addressFormik.errors.address_complement}
                                                        />
                                                    </Grid>
                                                    {addressFormik.values.address_country !== 'BR' && (<Grid item xs={12}> 
                                                        <Input 
                                                            label="Código Postal"
                                                            name="address_cep"
                                                            disabled={order.data.status !== 'AWAITING_ADDRESS'}
                                                            onChange={addressFormik.handleChange}
                                                            onBlur={addressFormik.handleBlur}
                                                            value={addressFormik.values.address_cep}
                                                            helperText={addressFormik.touched.address_cep && addressFormik.errors.address_cep}
                                                            error={!!addressFormik.touched.address_cep && !!addressFormik.errors.address_cep}
                                                        />
                                                    </Grid>)}
                                                    <Grid item xs={12}>
                                                        <Input 
                                                            label="Bairro"
                                                            name="address_neighborhood"
                                                            disabled={order.data.status !== 'AWAITING_ADDRESS'}
                                                            onChange={addressFormik.handleChange}
                                                            value={addressFormik.values.address_neighborhood}
                                                            helperText={addressFormik.touched.address_neighborhood && addressFormik.errors.address_neighborhood}
                                                            onBlur={addressFormik.handleBlur}
                                                            error={!!addressFormik.touched.address_neighborhood && !!addressFormik.errors.address_neighborhood}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Input 
                                                            label="Cidade"
                                                            name="address_city"
                                                            disabled={order.data.status !== 'AWAITING_ADDRESS'}
                                                            onChange={addressFormik.handleChange}
                                                            value={addressFormik.values.address_city}
                                                            helperText={addressFormik.touched.address_city && addressFormik.errors.address_city}
                                                            onBlur={addressFormik.handleBlur}
                                                            error={!!addressFormik.touched.address_city && !!addressFormik.errors.address_city}
                                                        />
                                                    </Grid>
                                                <Grid item xs={12}>
                                                    <Input 
                                                        label="Estado"
                                                        name="address_state"
                                                        disabled={order.data.status !== 'AWAITING_ADDRESS'}
                                                        onChange={(event) => { 
                                                            addressFormik.setFieldValue('address_state', event.target.value.replace(/[^a-zA-z]/ig, '').toLocaleUpperCase().substring(0, 2), true)
                                                        }}
                                                        value={addressFormik.values.address_state}
                                                        helperText={addressFormik.touched.address_state && addressFormik.errors.address_state}
                                                        onBlur={addressFormik.handleBlur}
                                                        error={!!addressFormik.touched.address_state && !!addressFormik.errors.address_state}
                                                    />
                                                </Grid>
                                                </Fragment>
                                                )}
                                                <Grid item marginTop={2} xs={12}>
                                                    <Button disabled={!addressFormik.isValid || order.data.status !== 'AWAITING_ADDRESS'} onClick={confirmModalVisibilityControls.setTrue}>{!order.data?.buyer?.address_street ? 'Cadastrar' : 'Atualizar' }</Button>
                                                </Grid>
                                            </Fragment>) : (
                                                <Grid item xs={12} textAlign="left">
                                                    <Alert severity={"warning"}>
                                                        <AlertTitle>Atenção!</AlertTitle>
                                                        Preenchimento liberado em: <strong>{moment(order.data.created_at).add(8, 'days').format('DD/MM/YYYY HH:mm:ss')}</strong>
                                                    </Alert>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </form>
                                    ) : (<form onSubmit={formik.handleSubmit}>
                                        <Grid container padding={2} spacing={2} justifyContent="center" textAlign={"center"}>
                                            <img src="/logo.png" alt="Logo" style={{width: '50%', marginBottom: 50}}/>
                                            <Alert color="warning" icon={false}>
                                                <p style={{textAlign: 'center', fontWeight: 'bold', fontSize: 'large'}}>ATENÇÃO - PRAZO DE ENVIO DO BRINDE!</p>
                                                <ul style={{textAlign: 'left'}}>
                                                    <li>Todos os endereços informados entre o dia <strong>primeiro</strong> e <strong>último</strong> do mês é feito o despacho até o dia 15 do mês seguinte e então começa a contar o prazo de 60 dias dos correios.</li>
                                                    <li>Envios internacionais poderão ter prazo de mais 30 dias, adicionalmente.</li>
                                                    <li>O HP da sua compra está no e-mail que recebeu no ato da inscrição, basta procurar por Hotmart no seu provedor ou entrar na sua conta no Hotmart e pegar o número.</li>
                                                </ul>
                                            </Alert>
                                            <Grid item xs={12}> 
                                                <Input 
                                                    label="HP"
                                                    name="transaction"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.transaction}
                                                    helperText={formik.touched.transaction && formik.errors.transaction}
                                                    error={!!formik.touched.transaction && !!formik.errors.transaction}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Input 
                                                    label="E-mail"
                                                    name="email"
                                                    onChange={formik.handleChange}
                                                    helperText={formik.touched.email && formik.errors.email}
                                                    onBlur={formik.handleBlur}
                                                    error={!!formik.touched.email && !!formik.errors.email}
                                                />
                                            </Grid>
                                            <Grid item marginTop={2} xs={12}>
                                                <Button disabled={!formik.isValid} type="submit">Buscar</Button>
                                            </Grid>
                                        </Grid>
                                    </form>)}
                                </Grid>
                )
            }
        </Grid>
    )
}